import React from 'react'
import Header from './Header'
import Footer from './Footer'
import '../css/style.scss'

export default function Layout({ children }) {
    return (
        <div>
            <Header />
            { children }
            <img className="footer-waves" src="/img/footer-waves.svg" alt="" width="900" height="48" />
            <Footer />
        </div>
    )
}
