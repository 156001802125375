import { Link } from 'gatsby'
import React from 'react'

export default function Header() {
    return (
        <header className="header">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-auto">
                    <Link className="header__logo" to="/">
                        <img src="/img/logo.svg" width="107" height="128"
                            alt="Use Less Divs logo, if you click on it, it takes you to the main page."/>
                    </Link>
                </div>
                <div className="col">
                    <nav>
                        <ul className="nav">
                            <li><Link className="nav__link" to="/blog/">Blog</Link></li>
                            <li><Link className="nav__link me-0" to="/read">Reading list</Link></li>
                        {/*    <li><Link className="nav__link me-0" to="/til" aria-label="Today I learned">TIL</Link></li> */}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    )
}
