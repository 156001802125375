import React from 'react'
import { Link } from 'gatsby'

export default function Footer() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="footer__logo-wrapper">
                <img
                  width="417"
                  height="47"
                  className="footer__logo"
                  src="/img/logo2.svg"
                  alt="Use Less Divs logo"
                />
                <p className="footer__quote">
                  What is necessary for one, is comfortable for the other.
                </p>
              </div>
            </div>
            <div className="col-12 text-center">
              <p className="footer__copyright">
                Use Less Divs © 2021 - Present | <Link to="/blog/introduction">About</Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    )
}
